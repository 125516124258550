import React from "react";
import { inject, observer } from "mobx-react";
import BaseMLBTrackingAccuracyReportContainer from "./BaseMLBTrackingAccuracyReportContainer";
import NonMLBTrackingAccuracyReportTable from "../components/accuracyReports/NonMLBTrackingAccuracyReportTable";
import IncidentTypes from "../constants/IncidentTypes";
import AAAGameActionDropDownContainer from "./AAAGameActionDropDownContainer";

class AAATrackingAccuracyReportContainer extends React.Component {
  render() {
    const rootStore = this.props.rootStore;
    const description =
      "A real-time pitch-by-pitch data report that monitors the overall quality of data and video systems used for AAA games.";
    console.log(rootStore);
    const reportTable = (
      <NonMLBTrackingAccuracyReportTable
        data={rootStore.AAAGameActionStore.trackingAccuracyReportSource.data}
        gameStatuses={rootStore.veracityStore.gameStatuses}
        canCreateIncident={rootStore.authStore.hasIncidentEdit}
        groupsToShow={rootStore.tableControlsStore.state}
        dropDownContainer={AAAGameActionDropDownContainer}
      />
    );
    return (
      <BaseMLBTrackingAccuracyReportContainer
        header="AAA Statcast Data & Coaching Video Report"
        description={description}
        reportSource={rootStore.AAAGameActionStore.trackingAccuracyReportSource}
        reportTable={reportTable}
        incidentTypesToFetch={[IncidentTypes.AAA, IncidentTypes.SYSTEM]}
        showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
      />
    );
  }
}

export default inject("rootStore")(
  observer(AAATrackingAccuracyReportContainer),
);
