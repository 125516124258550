import { extendObservable, computed, action } from "mobx";
import { RouterStore } from "mobx-react-router";
import { RouteConstants } from "../constants/RouteConstants";

class SetiRouterStore extends RouterStore {
  constructor() {
    super();

    extendObservable(this, {
      referrer: null,
      setReferrer: action(() => {
        this.referrer = `${this.location.pathname}${this.location.search}`;
      }),
      goToReferrer: action(() => {
        this.replace(this.referrer);
        this.referrer = null;
      }),
      isMLBTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.BASE,
      ),
      isMILBTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.MILB_TRACKING,
      ),
      isAtlanticTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.ATLANTIC_TRACKING,
      ),
      isAFLTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.AFL_TRACKING,
      ),
      isFSLTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.FSL_TRACKING,
      ),
      isNonMLBTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.NON_MLB_STATCAST,
      ),
      isAAATrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.AAA_TRACKING,
      ),
      isAATrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.AA_TRACKING,
      ),
      isHighATrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.HighA_TRACKING,
      ),
      isATrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.A_TRACKING,
      ),
      isRookieTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.ROOKIE_TRACKING,
      ),
      isAmateurTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.AMATEUR_TRACKING,
      ),
      isWinterTrackingAccuracyReportTab: computed(
        () => this.location.pathname === RouteConstants.WINTER_TRACKING,
      ),
      isInternationalIndependentTrackingAccuracyReportTab: computed(
        () =>
          this.location.pathname ===
          RouteConstants.INDEPENDENT_INTERNATIONAL_TRACKING,
      ),
      isLatestIncidentsTab: computed(
        () => this.location.pathname === RouteConstants.LATEST_INCIDENTS,
      ),
    });
  }
}

export default SetiRouterStore;
