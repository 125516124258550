import React from "react";
import { inject, observer } from "mobx-react";
import PaginatedTable from "../components/elements/PaginatedTable";
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styled from "styled-components";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IncidentTypes from "../constants/IncidentTypes";
import { toJS } from "mobx";
import moment from "moment";
import { Info } from "@material-ui/icons";

const FilterWrapper = styled.div`
  margin: 12px 16px !important;
  padding-top: 6px !important;
  font-size: 1rem;
  line-height: 1.5;
`;

const FilterLabel = styled.legend`
  margin-bottom: 6px !important;
`;

class StackedBox extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Box width={"100%"}>{this.props.comp1}</Box>
        <Box width={"100%"} color={"#a2a2a2"} fontSize={"0.8rem"}>
          {this.props.comp2}
        </Box>
      </React.Fragment>
    );
  }
}

class LatestIncidentsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.paginationChange = this.paginationChange.bind(this);
  }

  paginationChange(page, rowsPerPage) {
    const { latestIncidentsStore } = this.props.rootStore;
    latestIncidentsStore.updatePage(page);
    latestIncidentsStore.updateLimit(rowsPerPage);
    latestIncidentsStore.pushUrlParams();
  }

  render() {
    const { latestIncidentsStore } = this.props.rootStore;

    console.log(latestIncidentsStore);

    const mapRow = (row) => {
      const latestUpdate =
        row.incidentUpdates && row.incidentUpdates.length > 0
          ? row.incidentUpdates[0]
          : {};

      // Game date comes back in an invalid format for parsing (e.g. 2021-01-30T16:00Z[UTC])
      const gameDate =
        row.game &&
        row.game.gameDate &&
        moment
          .utc(row.game.gameDate.substr(0, row.game.gameDate.indexOf("Z") + 1))
          .local();

      const createdDate = moment(row.createdAt);
      const incidentDate = moment(row.incidentDate);
      const updatedDate = moment(latestUpdate.createdAt);
      const etaDate = latestUpdate.eta && moment(latestUpdate.eta);

      const getIncidentTypeLink = () => {
        switch (row.incidentType.id) {
          case IncidentTypes.MLB.id:
            return `/?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.MILB.id:
            return `/milb/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.NON_MLB.id:
            return `/nonmlb/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.AAA.id:
            return `/aaa/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.AA.id:
            return `/aa/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.HighA.id:
            return `/high-a/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.A.id:
            return `/a/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.ROK.id:
            return `/rookie/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.WIN.id:
            return `/amateur/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.Amateur.id:
            return `/winter/tracking?date=${getFormattedDate(gameDate)}`;
          case IncidentTypes.InternationalIndependent.id:
            return `/independent-international/tracking?date=${getFormattedDate(
              gameDate,
            )}`;
          default:
            return `/incidents`;
        }
      };

      return (
        <TableRow key={row.id}>
          <TableCell component="th" scope="row">
            <StackedBox
              comp1={getFormattedTime(updatedDate)}
              comp2={getFormattedDate(updatedDate)}
            />
          </TableCell>
          <TableCell>
            <StackedBox
              comp1={getFormattedTime(createdDate)}
              comp2={getFormattedDate(createdDate)}
            />
          </TableCell>
          <TableCell>
            <StackedBox
              comp1={
                gameDate !== undefined ? (
                  <Link href={getIncidentTypeLink()}>
                    {getFormattedDate(gameDate)}
                  </Link>
                ) : (
                  getFormattedDate(incidentDate)
                )
              }
              comp2={getFormattedTime(gameDate)}
            />
          </TableCell>
          <TableCell>
            <StackedBox
              comp1={getFormattedMatchup(row.game)}
              comp2={row.incidentType.name}
            />
          </TableCell>
          <TableCell>{row.game?.trackingVersion?.owner?.name ?? ""}</TableCell>
          <TableCell>{row.gamePk}</TableCell>
          <TableCell>
            <Link href={`/incidents/${row.id}`}>{row.name}</Link>
          </TableCell>
          <TableCell>
            <StackedBox
              comp1={getFormattedTime(etaDate)}
              comp2={getFormattedDate(etaDate)}
            />
          </TableCell>
          <TableCell>{latestUpdate.severity}</TableCell>
          <TableCell>{latestUpdate.status}</TableCell>
          <TableCell>{latestUpdate.description}</TableCell>
        </TableRow>
      );
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Backdrop
          style={{ color: "#fff", zIndex: 10 }}
          open={latestIncidentsStore.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <FormControl component={FilterWrapper}>
          <FormLabel component={FilterLabel}>System Owner</FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={latestIncidentsStore.get("owner")}
            exclusive
            onChange={(e) => {
              latestIncidentsStore.updateOwner(e.currentTarget.value);
            }}
          >
            <ToggleButton value="MLB">MLB</ToggleButton>
            <ToggleButton value="CLUB">Club Owned</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>

        <FormControl component={FilterWrapper}>
          <FormLabel component={FilterLabel}>Type</FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={toJS(latestIncidentsStore.get("incidentTypes"))
              .split(",")
              .filter((type) => type.length > 0)
              .map((type) => parseInt(type, 0))}
            onChange={(e, values) => {
              latestIncidentsStore.updateIncidentTypes(e.currentTarget.value);
            }}
          >
            <ToggleButton value={IncidentTypes.MLB.id}>MLB</ToggleButton>
            <ToggleButton value={IncidentTypes.NON_MLB.id}>
              Non-MLB Statcast
            </ToggleButton>
            <ToggleButton value={IncidentTypes.AAA.id}> AAA</ToggleButton>
            <ToggleButton value={IncidentTypes.AA.id}> AA</ToggleButton>
            <ToggleButton value={IncidentTypes.HighA.id}> High-A </ToggleButton>
            <ToggleButton value={IncidentTypes.A.id}> A</ToggleButton>
            <ToggleButton value={IncidentTypes.ROK.id}> Rookie</ToggleButton>
            <ToggleButton value={IncidentTypes.Amateur.id}>
              Amateur
            </ToggleButton>
            <ToggleButton value={IncidentTypes.WIN.id}> Winter</ToggleButton>
            <ToggleButton value={IncidentTypes.InternationalIndependent.id}>
              Int & Indy
            </ToggleButton>
            <ToggleButton
              value={IncidentTypes.MILB.id}
              style={{ textTransform: "none" }}
            >
              MiLB Logged
            </ToggleButton>
            <ToggleButton value={IncidentTypes.SYSTEM.id}>System</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <FormControl component={FilterWrapper}>
          <FormLabel component={FilterLabel}>Status</FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={latestIncidentsStore.get("status")}
            exclusive
            onChange={(e) => {
              latestIncidentsStore.updateStatus(e.currentTarget.value);
            }}
          >
            <ToggleButton value="open">Open</ToggleButton>
            <ToggleButton value="resolved">Resolved</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <FormControl component={FilterWrapper}>
          <FormLabel component={FilterLabel}>Severity</FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={latestIncidentsStore.get("severity")}
            exclusive
            onChange={(e) => {
              latestIncidentsStore.updateSeverity(e.currentTarget.value);
            }}
          >
            <ToggleButton value="critical">Critical</ToggleButton>
            <ToggleButton value="major">Major</ToggleButton>
            <ToggleButton value="minor">Minor</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <FormControl component={FilterWrapper}>
          <FormLabel component={FilterLabel}>
            Year
            <Tooltip
              title="Find incidents updated since 2/15 of the selected year"
              placement={"top"}
            >
              <IconButton style={{ padding: "0px 0px 4px 4px" }}>
                <Info style={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </FormLabel>
          <DatePicker
            value={moment(latestIncidentsStore.get("updateDate"))
              .tz("America/New_York")
              .set("month", 1) // 0-indexed so 1 is february
              .set("date", 15)
              .toISOString()}
            views={["year"]}
            emptyLabel
            openTo="year"
            onChange={(newDate) => {
              latestIncidentsStore.updateDate(
                moment(newDate)
                  .tz("America/New_York")
                  .set("month", 1) // 0-indexed so 1 is february
                  .set("date", 15)
                  .format("YYYY-MM-DD"),
              );
            }}
          />
        </FormControl>
        <PaginatedTable
          rows={toJS(latestIncidentsStore.data.data)}
          count={latestIncidentsStore.data.total}
          headers={[
            { label: "Updated At (ET)", width: "8%" },
            { label: "Opened At (ET)", width: "8%" },
            { label: "Game Time (ET)", width: "8%" },
            { label: "Matchup", width: "10%" },
            { label: "System Owner", width: "10%" },
            { label: "GamePK", width: "auto" },
            { label: "Incident Title", width: "15%" },
            { label: "ETA", width: "8%" },
            { label: "Severity", width: "auto" },
            { label: "Status", width: "auto" },
            { label: "Latest Update", width: "auto" },
          ]}
          page={parseInt(latestIncidentsStore.get("page"))}
          rowsPerPage={parseInt(latestIncidentsStore.get("limit"))}
          mapRow={mapRow}
          onChange={this.paginationChange}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

function getFormattedDate(date) {
  if (!date) return "N/A";
  return date.format("YYYY-MM-DD");
}

function getFormattedTime(date) {
  if (!date) return "N/A";
  return date.format("LT");
}

function getFormattedMatchup(game) {
  if (!game) return "N/A";

  return (
    <Link href={`https://research.mlb.com/games/${game.gamePk}/quality`}>
      {game.teams.away.team.abbreviation}@{game.teams.home.team.abbreviation}
    </Link>
  );
}

export default inject("rootStore")(observer(LatestIncidentsContainer));
